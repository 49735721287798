var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("cs-card", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "content-separator" },
          [
            _c(
              "cs-flex",
              { staticClass: "logo-container", attrs: { justify: "center" } },
              [
                _c("on-stock-logo-svg", {
                  staticClass: "logo",
                  attrs: { color: "#424b5a" },
                }),
              ],
              1
            ),
            _c("h3", [_vm._v(_vm._s(_vm.$t("Stock management system")))]),
            _c(
              "cs-flex",
              { staticClass: "button-container", attrs: { vertical: true } },
              [
                _c(
                  "cs-btn",
                  {
                    attrs: { dark: "", color: "#424b5a" },
                    on: { click: _vm.openAuthWindow },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Consteel login")) + " ")]
                ),
                _c(
                  "cs-btn",
                  {
                    attrs: { dark: "", color: "#424b5a" },
                    on: { click: _vm.openMicrosoftAuthWindow },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Microsoft login")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }